<template>
    <div>hello getUserInfo</div>
</template>

<script>
import { getInfo } from '../api/index'
import { useRoute, useRouter } from 'vue-router'

export default {
    name: 'getUserInfo',
    setup(){
        const router = new useRouter();
        const getUserInfo = (data)=>{
            let params = {code:data}
            getInfo(params).then((res)=>{
                console.log(res);
                window.localStorage.setItem('openid',res.data.openid);
                router.push({
                    name:'Index',
                    query:{'weixin':1}
                })    
            });                
        }

        return{
            getUserInfo
        }

    },
    mounted(){
        // this.getUserInfo();
        const route = new useRoute();
        // console.log(route.query);
        this.getUserInfo(route.query.code);

    }
}
</script>